//
// Theme style
//

// Initialize
@import "init";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";

.swal2-styled.swal2-confirm {
    background-color: #54c5f1 !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px #54c5f1 !important;
}

.swal2-styled.swal2-cancel {
    background-color: red !important;
}

.print-only {
    display: none;
}

@media print {

    /* Hide the header and any other elements you want to skip */
    #kt_app_header_container,
    #kt_app_toolbar,
    #printBtn,
    #printModalHeader,
    #printModalFooter,
    #printDiv,
    #kt_app_sidebar,
    #kt_app_header,
    #kt_app_toolbar,
    #kt_app_footer {
        display: none !important;
    }

    .print-only {
        display: block;
    }
}

.customInnerHtmlCSS img {
    width: 30%;
    padding-bottom: 15px;
}

.text-muted {
    color: #7E8299 !important;
}

.card {
    margin-bottom: 1rem;
}

// form .card-body {
//     padding: 2rem 10.25rem !important;
// }

.modal-body {
    .card-body {
        padding: 0.75rem 0.25rem !important;
    }

    .card-header {
        padding: 0rem 0rem !important;
    }

    h4 {
        font-size: 1rem !important;
    }
}

.modal-header {
    padding: 1rem 1rem 0rem 1rem !important;
    background-color: #e3e6e9 !important;
}

form .card-body-fixed,
.tb-bg-dark .card-body-fixed {
    padding: 0.5rem 1.25rem !important;
}

form .card .card-header,
.tb-bg-dark .card .card-header {
    background-color: #fafafa !important;
}

select2.form-control.form-control-solid {
    padding: 0.0rem !important;
}

.card-xxl-stretch .card-header {
    background-color: #e3e6e9 !important;
}

.card-header {
    padding: 0.1rem 1.25rem !important;

}

.card .card-header-stretch {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.menu-item .menu-link {
    padding: 0.35rem 1rem !important;
}

// .dataTables_empty {
//     padding-top: 40px !important;
//     font-size: 18px !important;
//     font-weight: 500 !important;
// }

table thead tr {
    text-transform: uppercase !important;
}

table thead {
    background-color: #e3e6e9 !important;
}

.customErrorMessageColor {
    color: red !important;
}

@include media-breakpoint-up(lg) {
    .view-booking-page {
        padding: 0 20rem;
    }
}


a[disabled] {
    pointer-events: none;
    color: gray;
    text-decoration: none;
}


.form-control-disabled {
    background-color: #e9ecef;
    color: #6c757d;
    border: 1px solid #ced4da;
    cursor: not-allowed;
}

.fw-600 {
    font-weight: 600;
}

.form-check-input[type="checkbox"] {
    border: 2px solid var(--bs-gray-300) !important;
}

ngb-rating {
    color: yellow;
    font-size: 40px;
}

.custom-ngb-rating-color {
    color: #7E8299;
}

.size-20px {
    font-size: 20px !important;
}

.size-28px {
    font-size: 28px !important;
}

.invalid {
    border-color: red !important;
}

.card .card-header .card-title {
    font-size: 1.1rem !important;
}

.table tr,
.table th {
    text-transform: none !important;
}


.custom-date-range {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 0 0 1rem;
    width: auto !important;

    .mat-mdc-icon-button svg,
    .mat-mdc-icon-button img {
        height: 1.75rem !important;
        width: 1.75rem !important;
    }

    .mat-mdc-icon-button .mat-mdc-button-ripple,
    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
        background: transparent !important;
        border-radius: 0 !important;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        width: 48px;
        height: 48px;
        padding: 0.75rem !important;
        height: 3.5rem;
        width: auto;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
        height: 2.5rem !important;
        width: 2.5rem !important;
    }
}
.mat-calendar-body-selected {
    background-color: var(--bs-primary) !important;
}

.modal .fv-row {
    margin-bottom: 0.7rem !important;
}

app-classic {
    float: right;
}

.card-toolbar {
    // padding-left: 2.25rem !important;
    float: right;
    // width: 100%;
}

.task-list-wrapper {
    .card {
        border: 1px solid var(--bs-gray-300) !important;
        .card-body{
            padding: 1.25rem !important;
        }
    }
}

.bnb-filter{
    padding: 1rem 2rem;
    border: var(--bs-border-width) solid var(--bs-card-border-color);
    background: var(--bs-card-border-color);
}

.card-box-shadow {
    border: var(--bs-border-width) solid var(--bs-card-border-color);
}

h4 {
    font-size: 1.1rem !important;
    font-weight: 500 !important;
}