@import "~@ionic/angular/css/core.css";
@import '@angular/material/prebuilt-themes/indigo-pink.css';


/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

body {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-bottom: calc(4px + env(safe-area-inset-top));
}

#kt_app_sidebar {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-bottom: calc(4px + env(safe-area-inset-top));
}

.float-right {
  float: right;
}

span[role="alert"] {
  color: red;
}

.bg-grey {
  background-color: #eff1f3 !important;
}

.text-blue {
  color: #171940 !important;
}

.justify-items-center {
  justify-items: center !important;
}

.blue-bg {
  background: #54c5f1 !important;
  color: white !important;
}

.custom-user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 25px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin: 2px 0;
}

.custom-table-responsive {
  min-height: 500px;
}

.custom-table-border {
  border: 2px solid #e3e6e9 !important;
}

.cur-ptr {
  cursor: pointer;
}

.w-194px {
  width: 194px !important;
}

.h-154px {
  height: 154px !important;
}

.search-icon {
  border-radius: 0 0.475rem 0.475rem 0;
}

swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px rgb(255 0 0);
}

/* Select2 style */
.select2-container {
  width: 100% !important;

  .select2-selection--single,
  .select2-selection--multiple {
    height: 3.25rem !important;
    border-radius: 0.375rem !important;
    border: 1px solid #ced4da !important;
    background-color: #fff !important;
    color: #495057;
    font-size: 1rem;
    padding: 0.775rem 1rem;
    

    .select2-selection__rendered {
      line-height: 1.5 !important;
      padding-left: 0 !important;
      font-size: 1.1rem !important;
      font-weight: 500;
    }

    .select2-selection__arrow {
      position: relative;
      height: 3.25rem !important;
      top: 50%;
      transform: translateY(-50%);
    }

    &:focus {
      outline: none;
    }
  }

  .select2-dropdown {
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
  }

  .select2-results__option {
    padding: 0.5rem 1.25rem !important;
    color: #495057;
  }

  .select2-results__option--highlighted {
    background-color: #f8f9fa;
    color: #007bff;
  }

  .select2-dropdown {
    max-height: 300px;
    overflow-y: auto;
  }

  .select2-selection--multiple {
    min-height: 3.25rem !important;
    height: auto !important;
    padding: 3px 1rem !important;
  }
}

.invalid .select2-container .select2-selection--single,
.invalid .select2-container .select2-selection--multiple {
  border-color: red !important;
}


.fv-plugins-message-container .select2-container,
.fv-plugins-message-container {
  margin-top: 0 !important;
}

.select2-search__field {
  border-radius: 0.375rem !important;
  border: 1px solid #ced4da !important;
  background-color: #fff !important;
  color: #495057 !important;
  font-size: 1rem !important;
  padding: 0.775rem 1rem !important;

  &:focus {
    color: var(--bs-gray-700);
    background-color: var(--bs-body-bg);
    border-color: var(--bs-gray-400);
    outline: 0;
    box-shadow: false, 0 0 0 0.25rem rgba(var(--bs-component-active-bg), 0.25);
  }
}

.ngx-pagination {
  margin-bottom: 0 !important;
  margin: 0 1rem;
  padding-left: 1rem !important;

  li {
    margin: 0 .2em;
    border: 1px solid var(--bs-gray-300);
    border-radius: 0.25em;
    
    a {
      padding: 0.55rem 1rem !important;
    }

    &.current {
      background-color: var(--bs-primary) !important;
      border: 1px solid var(--bs-primary) !important;
      border-radius: 0.25em;
      color: #ffffff;
      pointer-events: none;      
      padding: 0.55rem 1rem !important;
    }

    &.disabled {
      padding: 0.55rem 1rem !important;
    
    }

    &.pagination-previous a,
    &.pagination-previous.disabled,
    &.pagination-next a,

    &.pagination-previous a:before,
    &.pagination-previous.disabled:before {
      content: "\2190";
      display: inline-block;
    }

    &.pagination-next a:after,
    &.pagination-next.disabled:after {
      content: "\2192";
      display: inline-block;
    }

    &.pagination-previous, &.pagination-next{
      background-color: var(--bs-primary);
      color: #ffffff;
      border: 1px solid var(--bs-primary);
      border-radius: 0.25em; 
      font-weight: bold;
    }

    &.pagination-previous.disabled, &.pagination-next.disabled{
      background-color: var(--bs-gray-300) !important;
      border: 1px solid var(--bs-gray-300) !important;
    }    

  }
}

.pagination-select {
  padding-top: 0.55rem !important;
  padding-bottom: 0.55rem !important;
  padding-left: 0.75rem !important;
  border-radius: 0.425rem !important;
}

.col-form-label {
  padding-top: calc(0.1rem + 1px);
  padding-bottom: calc(0.1rem + 1px);
}

form .card .card-header,
.tb-bg-dark .card .card-header,
.card-header.card-header-dark {
  background-color: #e3e6e9 !important;
}

app-header {
  max-height: 60px;
}

app-sidebar-menu {
  font-size: 1.1rem;

}

[data-kt-app-layout=dark-sidebar] .app-sidebar {
  background-color: #fff;
}

[data-kt-app-layout=dark-sidebar] .app-sidebar .app-sidebar-logo {
  border-bottom: 1px dashed #E1E3EA;
}

[data-kt-app-layout=dark-sidebar] .app-sidebar .btn-custom {
  background-color: #54c5f1;
  color: #fff;
}

[data-kt-app-layout=dark-sidebar] .app-sidebar .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title {
  color: #54c5f1;
}

[data-kt-app-layout=dark-sidebar] .app-sidebar .menu .menu-item .menu-link .menu-title {
  color: var(--bs-gray-900);
  letter-spacing: 0.5px;
}

.menu-item .menu-link .menu-arrow:after {
  color: #54c5f1 !important;
}

[data-kt-app-layout=dark-sidebar] .app-sidebar .menu .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here) .menu-arrow:after, [data-kt-app-layout=dark-sidebar] .app-sidebar .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-arrow:after {
  background-color: #54c5f1 !important;
}

.h-120px {
  height: 120px !important;
}

[data-kt-app-layout=dark-sidebar] .app-sidebar .menu .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here) .menu-title, [data-kt-app-layout=dark-sidebar] .app-sidebar .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title {
  color: var(--bs-gray-900); 
}

[data-kt-app-layout=dark-sidebar] .app-sidebar .menu .menu-item .menu-link.active {
  background-color: #54c5f1 !important; 
  color: #fff !important;
}

@media (min-width: 992px) {
  :root {
    --bs-app-header-height: 40px;
    --bs-app-header-height-actual: 60px;
  }
}

.card-toolbar {
  .btn.btn-icon.btn-sm {
    width: auto !important;
    margin-top: 0 !important;
    height: calc(1.5em + 1rem) !important;
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .app-sidebar-toggle {
    position: absolute !important;
    top: 20px !important;
  }
}

[data-bs-theme=light] {
  --bs-success: #209a51;
  --bs-text-success: #209a51;
}

.text-muted {
  color: #5f6368 !important;
}

.scroll-y {
  overflow-y: auto !important;
  position: relative;
}

.modal {
  .modal-content {
    .modal-header {
      h4 {
        margin-bottom: 0.75rem !important;
      }

      .btn.btn-icon {
        align-items: start !important;
        width: auto !important;
        padding: 0 !important;
      }
    }
    .modal-body{
      min-height: 340px;
    }
  }

  .fv-row.mb-0 {
    margin-bottom: 0 !important;
  }

  .modal-footer>* {
    margin: 0 !important;
  }
}

.badge-light-warning {
  color: rgba(0, 0, 0, 0.54) !important;
  background-color: var(--bs-warning) !important;
}

.btn {
  font-weight: bold;
  font-size: 13px;
  // padding: 0.6rem 1.4rem !important;  
}
.public-container {
  margin: 0 20px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.td-center{
  display: table-cell !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff !important;
}

.border-solid {
  border-style: solid;
  border-color: var(--bs-border-dashed-color);
  padding: 1rem;
}