//
// Modal
//

.modal {
	.fv-row {
		margin-bottom: 1rem !important;
		select2 {
			padding: 0.375rem 0.5rem;
		}
	}
}

.modal-header {
	align-items: center;
	justify-content: space-between;
	@include border-top-radius($modal-content-border-radius);
	border-bottom: $modal-header-border-width solid var(--bs-modal-header-border-color);
	padding-bottom: 0.5rem !important;	
}

.modal-footer {
	background-color: var(--bs-modal-footer-bg);
	border-top: $modal-footer-border-width solid var(--#{$prefix}modal-footer-border-color);
	padding: 1rem !important;	
}

// .modal {
//   	--#{$prefix}modal-color: var(--bs-modal-content-color);
//   	--#{$prefix}modal-bg: var(--bs-modal-content-bg);
//   	--#{$prefix}modal-border-color: var(--bs-modal-content-border-color);
//   	--#{$prefix}modal-box-shadow: var(--bs-modal-content-box-shadow-xs);
//   	--#{$prefix}modal-header-border-color: var(--bs-modal-header-border-color);
//   	--#{$prefix}modal-footer-bg: var(--bs-modal-footer-bg);
//   	--#{$prefix}modal-footer-border-color: var(--bs-modal-footer-border-color);
// }

//  // Mobile dialog
// .modal-dialog {
// 	outline: none !important;
// }

// // Modal header
// .modal-header {
// 	align-items: center;
// 	justify-content: space-between;
// 	@include border-top-radius($modal-content-border-radius);
// 	border-bottom: $modal-header-border-width solid var(--bs-modal-header-border-color);

// 	// Headings
// 	h1,h2,h3,h4,h5,h6 {
// 		margin-bottom: 0;
// 	}
// }

// // Modal content
// .modal-content {
// 	color: var(--bs-modal-color);
// 	background-color: var(--bs-modal-bg);
// 	border: $modal-content-border-width solid var(--bs-modal-border-color);
// 	@include box-shadow(var(--bs-modal-box-shadow));
// }

// // Modal footer
// .modal-footer {
// 	background-color: var(--bs-modal-footer-bg);
// 	border-top: $modal-footer-border-width solid var(--#{$prefix}modal-footer-border-color);
// }

// // Modal background
// .modal-backdrop {
//   	--#{$prefix}backdrop-bg: var(--bs-modal-backdrop-bg);
//   	--#{$prefix}backdrop-opacity: var(--bs-modal-backdrop-opacity);
// }

// // Scale up the modal
// @include media-breakpoint-up(sm) {
// 	.modal-content {
// 		@include box-shadow(var(--bs-modal-box-shadow-sm-up));
// 	}
// }

// // Utilities
// .modal-rounded {
// 	@if $enable-rounded {
// 		border-radius: $modal-content-border-radius !important;
// 	}
// }
